import React from 'react'
import { Button } from 'react-bootstrap'

function AnotherLoginButton({iconSrc, text, handleClickEvent}) {
  return (
    <Button onClick={handleClickEvent}>
      <img src={iconSrc} className="anotherLoginImg"/>
      {text}
    </Button>
  )
}

export default AnotherLoginButton