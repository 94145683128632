/* eslint-disable jsx-a11y/control-has-associated-label, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-to-interactive-role*/
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { client as prismicClient } from "../libs/prismic";
import { RichText } from "prismic-reactjs";
import AnotherLoginButton from "../components/LoginButton";
import TextInputField from "../components/TextInputField";
import { Link } from "gatsby";
import Loader from "../components/Loader";
import "../styles/pages/login.scss";

const Login = () => {
  const [dataDoc, setDataDoc] = useState();
  useEffect(() => {
    async function fetchData() {
      const response = await prismicClient().getSingle("login");
      setDataDoc(response);
    }
    fetchData();
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const [errors, setErrors] = useState({
    email: "",
  });

  const handleChange = (prop) => (event) => {
    if (prop === "password") {
      setValues({ ...values, [prop]: event.target.value });
    } else {
      if (event.target.value) {
        if (validateEmail(event.target.value)) {
          setValues({ ...values, [prop]: event.target.value });
          setErrors({ ...errors, [prop]: "" });
        } else {
          setErrors({
            ...errors,
            [prop]: "Please enter a valid email address",
          });
        }
      } else {
        setErrors({ ...errors, [prop]: "This field is required" });
      }
    }
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleForgotPass = () => {
    alert("Forgot Password Button clicked");
  };
  const handleGoogleLogin = () => {
    alert("Continue with Google button clicked");
  };
  const handleMicrosoftLogin = () => {
    alert("Continue with Microsoft Account");
  };
  const handleAppleLogin = () => {
    alert("Continue with Apple");
  };
  const handleLogin = () => {
    alert("Signin currently not permitted.");
  };

  return dataDoc ? (
    <div className="login-page-bg">
      <div className="login-page">
        <div className="d-flex flex-column align-items-center login">
          <div className="login-image-container">
            <Link to="/">
              <img src={dataDoc.data?.logo?.url} alt="Brand Logo" />
            </Link>
          </div>
          <div className="login-text-container">
            <p className="m-0">{dataDoc.data?.title?.[0].text}</p>
          </div>
          <div className="login-form-container d-flex flex-column">
            <div>
              <TextInputField
                label="Email"
                variant="filled"
                InputProps={{
                  disableUnderline: true,
                  style: { borderColor: errors.email && "#C75E5A" },
                }}
                onChange={handleChange("email")}
                style={{ width: "100%" }}
                InputLabelProps={{
                  style: {
                    color: errors.email ? "#C75E5A" : "rgba(0, 0, 0, 0.5)",
                  },
                }}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div>
              <TextInputField
                label="Password"
                value={values.password}
                type={values.showPassword ? "text" : "password"}
                onChange={handleChange("password")}
                variant="filled"
                style={{ marginTop: 10, width: "100%" }}
                InputLabelProps={{ style: { color: "rgba(0, 0, 0, 0.5)" } }}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <img
                      role="button"
                      onClick={handleClickShowPassword}
                      src={
                        values.showPassword
                          ? "/assets/images/hide_password_icon.svg"
                          : "/assets/images/show_password_icon.svg"
                      }
                    />
                  ),
                }}
              />
            </div>
          </div>
          <div className="login-btn-container">
            <Button onClick={handleLogin}>Log In</Button>
          </div>
          <div className="forgot-pass-container">
            <p
              className="p-0 m-0"
              role="button"
              onClick={() => handleForgotPass()}
            >
              Forgot password?
            </p>
          </div>
          <div className="or-container d-flex align-items-center w-100 justify-content-center mt-0">
            <hr />
            <h1 className="mt-0">or</h1>
            <hr />
          </div>
          <div className="other-login-btn-container d-flex flex-column">
            <AnotherLoginButton
              iconSrc="/assets/images/google_icon.svg"
              text="Continue with Google"
              handleClickEvent={handleGoogleLogin}
            />
            <AnotherLoginButton
              iconSrc="/assets/images/apple_icon.svg"
              text="Continue with Apple"
              handleClickEvent={handleAppleLogin}
            />
            <AnotherLoginButton
              iconSrc="/assets/images/microsoft_icon.svg"
              text="Continue with Microsoft"
              handleClickEvent={handleMicrosoftLogin}
            />
          </div>
          <div className="not-member-container">
            <RichText render={dataDoc.data?.formfooter} />
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default Login;
